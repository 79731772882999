'use client';

import * as braze from '@braze/web-sdk';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { useOnce } from '@mentimeter/react-hooks';
import { useSplits } from '@mentimeter/splitio';
import { useUser } from '@mentimeter/user';
import { regionalizeUserId } from './regionalize-user-id';
import { generateAuthenticationToken } from './generate-authentication-token';
import { isEligibleForBraze } from './lib/is-eligible-for-braze';

const BRAZE_BASE_URL = process.env.NEXT_PUBLIC_BRAZE_BASE_URL;
const BRAZE_PUBLIC_API_KEY = process.env.NEXT_PUBLIC_BRAZE_PUBLIC_API_KEY;

const BRAZE_ERROR_CODES = {
  SUBJECT_MISMATCH: 21,
  AUTHENTICATION_EXPIRED: 22,
  PAYLOAD_USER_ID_MISMATCH: 28,
};
const RETRYABLE_BRAZE_ERROR_CODES = [
  BRAZE_ERROR_CODES.SUBJECT_MISMATCH,
  BRAZE_ERROR_CODES.AUTHENTICATION_EXPIRED,
  BRAZE_ERROR_CODES.PAYLOAD_USER_ID_MISMATCH,
];

export function useInitializeBraze(): { isReady: boolean } {
  const { PUG_Braze, isReady } = useSplits(['PUG_Braze']);
  const { user } = useUser();

  const readyToInitialize =
    PUG_Braze === 'on' &&
    Boolean(user) &&
    Boolean(BRAZE_PUBLIC_API_KEY) &&
    Boolean(BRAZE_BASE_URL);

  useOnce(readyToInitialize, async () => {
    if (!user) return;
    if (!isEligibleForBraze(user.email)) return;

    const hasInitializedBraze = initializeBrazeSDK();
    if (!hasInitializedBraze) return;

    setupAuthenticationFailureHandler();

    const userId = regionalizeUserId(String(user.id), user.region);
    await initializeUserSession(userId);
  });

  return { isReady };
}

function initializeBrazeSDK(): boolean {
  const initialized = braze.initialize(BRAZE_PUBLIC_API_KEY!, {
    baseUrl: BRAZE_BASE_URL!,
    enableSdkAuthentication: true,
  });

  if (!initialized) {
    captureException(
      new MentiError('Failed to initialize Braze client', {
        feature: 'braze',
      }),
    );
  }

  return initialized;
}

function setupAuthenticationFailureHandler() {
  braze.subscribeToSdkAuthenticationFailures(async (error) => {
    if (!RETRYABLE_BRAZE_ERROR_CODES.includes(error.errorCode)) {
      captureException(
        new MentiError(
          `Braze SDK authentication failure with error code ${error.errorCode}`,
          { feature: 'braze' },
        ),
      );
      return;
    }

    await refreshAuthenticationToken();
  });
}

async function refreshAuthenticationToken() {
  const { success, message, token } = await generateAuthenticationToken({
    forceFetchNewAuthToken: true,
  });

  if (!success || !token) {
    captureException(
      new MentiError(
        `Failed to regenerate Braze authentication token: ${message}`,
        {
          feature: 'braze',
        },
      ),
    );
    return;
  }

  braze.setSdkAuthenticationSignature(token);
}

async function initializeUserSession(regionalizedUserId: string) {
  const { success, message, token } = await generateAuthenticationToken();

  if (!success || !token) {
    captureException(
      new MentiError(
        `Failed to generate Braze authentication token: ${message}`,
        {
          feature: 'braze',
        },
      ),
    );
    return;
  }

  braze.changeUser(regionalizedUserId, token);
  braze.openSession();
}
