'use client';

import React from 'react';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { useInitializeBraze } from './use-initialize-braze';

export function BrazeInitializer() {
  return (
    <ErrorBoundary
      feature="braze"
      errorMessage="Something went wrong initializing Braze"
    >
      <InnerBrazeInitializer />
    </ErrorBoundary>
  );
}

function InnerBrazeInitializer() {
  useInitializeBraze();
  return null;
}
